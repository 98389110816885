import { Col, Divider, Input, Row, Select, Space, Spin, Button, Empty, Grid } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReusableFilePreview from "../../components/custom/ReusableFilePreview";
import { SavedTemplatesSelect } from "../outbound/SavedTemplatesSelect";
import "./../../assets/css/automationMessages.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import useTemplates from "../../hooks/useTemplates";
import { FromNumberComponent, handleAddAutomation } from "../utils/CommonVessels";
import { mapToMediaList } from "../utils/commonUtils";
import ComponentPreview from "../../components/custom/ComponentPreview";
import { RenderOptionLabel } from "../../pages/utils/CommonVessels";
function AddOrEditAutomationComponent(props) {
  let {
    valueObj,
    friendlyNameRef,
    setValueObj,
    method,
    savedNumbersList,
    setSavedNumbersList,
    drawerOpen,
    setDrawerOpen,
    setFileList,
    setMessageAreaLoading,
    messageAreaLoading,
    fileList,
    showAddOrEditButton,
    appProperties,
    addBtnLoading,
    setAddBtnLoading,
    messageAreaHeight,
    setTableData,
    setWebhookUrl,
    setWebhookId,
    doNotShowEditConfigurationMessage,
    source,
  } = props;
  const { fetchData } = useHttp();
  const handleValueObj = useCallback(
    (newValueObj) => {
      setValueObj(newValueObj);
    },
    [setValueObj]
  );
  const handleMedia = (mediaFiles) => {
    setFileList(mapToMediaList(mediaFiles, false));
  };

  useEffect(() => {
    appProperties?.controller?.getAssociatedObjectType().then(function (currentModuleFromApplication) {
      if (currentModuleFromApplication) {
        handleValueObj((prev) => ({
          ...prev,
          event: currentModuleFromApplication,
        }));
      }
    });
  }, [appProperties?.controller]);

  const addAutomation = (mediaFiles) => {
    handleAddAutomation(
      valueObj,
      setAddBtnLoading,
      method,
      setDrawerOpen,
      fetchData,
      setValueObj,
      setTableData,
      appProperties,
      setWebhookUrl,
      setWebhookId
    );
  };

  const handleComponents = (componentsData) => {
    setValueObj((prev) => {
      return { ...prev, components: componentsData?.components };
    });
  };

  const handleRemoveComponents = () => {
    setValueObj((prev) => ({ ...prev, components: null }));
  };

  return (
    <Space direction="vertical w-100">
      Friendly Name
      <Input
        placeholder="Enter friendly name"
        className="rounded hs-bg-off-white focus-border h-45 font-size-14"
        value={valueObj?.friendlyName}
        ref={friendlyNameRef}
        onChange={(e) => {
          setValueObj((prev) => ({
            ...prev,
            friendlyName: e.target.value,
          }));
        }}
      />
      From
      <FromNumberComponent
        disabled={method.current === "edit" ? true : false}
        customClasses={"rounded hs-bg-off-white focus-border h-45 "}
        fromNumber={valueObj?.phoneNum}
        handleFromNumberChange={(e) => {
          setValueObj((prev) => ({ ...prev, phoneNum: e }));
          setValueObj((prev) => ({
            ...prev,
            integId: savedNumbersList?.find((obj) => obj?.phoneNumber === e)?.integId,
          }));
        }}
        savedNumbersList={savedNumbersList}
        setSavedNumbersList={setSavedNumbersList}
      />
      To
      <ToNumberComponent
        method={method.current}
        valueObj={valueObj}
        setValueObj={handleValueObj}
        drawerOpen={drawerOpen}
        setFileList={setFileList}
        source={source}
      />
      <div className="d-flex align-items-center justify-content-between mt-3 mb-0">
        <span>Message</span>
        <div className="w-auto">
          <SavedTemplatesSelect
            setMessageAreaLoading={setMessageAreaLoading}
            setTemplateId={(e) => {
              setValueObj((prev) => ({ ...prev, templateId: e }));
            }}
            setLoading={null}
            handleMessage={(e) => {
              setValueObj((prev) => ({ ...prev, message: e }));
            }}
            integrationId={valueObj?.integId}
            setPreviewResponse={handleComponents}
            handleMedia={handleMedia}
            loadTemplates={true}
            templateId={method.current === "edit" ? valueObj?.templateId : null}
            showPreview={method.current === "edit"}
            refetchOnModuleChange={true}
            moduleName={valueObj?.event}
          />
        </div>
      </div>
      <Spin spinning={messageAreaLoading}>
        <Row className="justify-content-center rounded hs-bg-off-white focus-border">
          <Col xs={24}>
            {valueObj.components ? (
              <ComponentPreview handleRemove={handleRemoveComponents} componentsData={valueObj.components} />
            ) : (
              <TextArea
                className="rounded m-2 font-size-14"
                disabled
                placeholder={"Your text goes here"}
                size="large"
                autoSize={{ maxRows: 8, minRows: messageAreaHeight ? messageAreaHeight : 8 }}
                variant="borderless"
                value={valueObj.message}
                onChange={(e) => {
                  setValueObj((prev) => ({
                    ...prev,
                    message: e.target.value?.trim(),
                  }));
                }}
              />
            )}

            <div className="d-flex align-items-end" style={{ minHeight: "48px" }}>
              <ReusableFilePreview
                fileList={fileList}
                setFileList={setFileList}
                shape={"circle"}
                direction={"horizontal"}
                customClasses={"ps-3 ms-1"}
              />
            </div>
          </Col>
        </Row>
        {showAddOrEditButton && (
          <Row>
            <div className="d-flex align-items-center justify-content-end w-100">
              {method.current === "edit" && doNotShowEditConfigurationMessage && (
                <>
                  <div className="p-3 w-100 trialAccount ">
                    <ExclamationCircleOutlined style={{ fontSize: 20, color: "#FF9800" }} />
                    <div>
                      After configuration editing, the webhook URL remains unchanged, no need to update the{" "}
                      {appProperties?.leftServiceDisplayName} automation
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex align-items-center justify-content-end">
                <Button loading={addBtnLoading} className="addTempBtn w-100 mt-3 justify-content-center hs-fs-16" onClick={addAutomation}>
                  {method.current === "edit" ? "Edit Configuration" : "Create Webhook URL"}
                </Button>
              </div>
            </div>
          </Row>
        )}
      </Spin>
    </Space>
  );
}

const ToNumberComponent = ({ valueObj, method, setValueObj, drawerOpen, setFileList, source }) => {
  const memoizedSetValueObj = useCallback(
    (newValueObj) => {
      setValueObj(newValueObj);
    },
    [setValueObj]
  );
  const { md } = Grid.useBreakpoint();
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const { fetchModules, fetchAllFields } = useTemplates();
  const [modulesList, setModulesList] = useState([]);
  const [associatedModules, setAssociatedModules] = useState([]);
  const [fieldOptions, setFieldOptions] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [disableModuleSelection, setDisableModuleSelection] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [moduleFieldsLoading, setModuleFieldsLoading] = useState(false);

  useEffect(() => {
    appProperties?.controller?.getAssociatedObjectType().then(function (currentModuleFromApplication) {
      if (currentModuleFromApplication) {
        memoizedSetValueObj((prev) => ({
          ...prev,
          event: currentModuleFromApplication,
        }));
        setDisableModuleSelection(true);
      }
    });
  }, [appProperties]);
  useEffect(() => {
    if (valueObj?.event) {
      setModuleFieldsLoading(true);
      fetchAllFields(appProperties, valueObj?.event).then(function (response) {
        if (response) {
          let apiResp = response?.fields?.map((field) => field);
          let filterPhoneOptionsArray = apiResp?.filter((field) => field.fieldType === "phone");
          let phoneFields = [];
          if (filterPhoneOptionsArray) {
            filterPhoneOptionsArray?.forEach((fieldObj) => {
              const obj = {
                ...fieldObj,
                moduleName: valueObj?.event,
                // fieldValue: valueObj?.event + "&&" + fieldObj?.fieldApiName,
                fieldValue: fieldObj?.fieldApiName,
              };
              phoneFields.push(obj);
            });
          }

          if (response && response?.lookups) {
            response?.lookups?.forEach((lookupModulePhoneField) => {
              let filteredLookupFields = lookupModulePhoneField?.fields?.filter((field) => field.fieldType === "phone");

              filteredLookupFields?.forEach((lookupFieldObj) => {
                const obj = {
                  ...lookupFieldObj,
                  moduleName: lookupModulePhoneField?.fieldApiName,
                  displayModuleName: lookupModulePhoneField?.fieldName,
                  fieldValue: lookupModulePhoneField?.fieldApiName + "&&" + lookupFieldObj?.fieldApiName,
                };
                phoneFields.push(obj);
              });
            });
          }
          setFieldOptions(phoneFields);
          setModuleFieldsLoading(false);
        } else {
          setModuleFieldsLoading(false);
        }
      });
    }
  }, [appProperties.serviceInstalled, appProperties, fetchData, fetchAllFields, valueObj?.event]);

  useEffect(() => {
    setModuleLoading(true);
    fetchModules(appProperties, null, "templates").then(function (response) {
      let modules = response;
      let allModules = modules?.data;
      allModules = allModules?.map((obj) => ({
        label: <span className="text-capitalize">{obj}</span>,
        value: obj,
      }));
      setModulesList(allModules);
      setModuleLoading(false);
    });
  }, [appProperties, fetchModules, method, memoizedSetValueObj]);

  useEffect(() => {
    if (fieldData && valueObj?.event) {
      let supportedFields = fieldData?.filter((obj) => associatedModules[valueObj?.event]?.includes(obj?.moduleName));
      setFieldOptions(supportedFields);
    }
  }, [fieldData, drawerOpen, valueObj?.event, associatedModules]);

  useEffect(() => {
    if (drawerOpen && method !== "edit") {
      memoizedSetValueObj((prev) => ({
        ...prev,
        event: modulesList?.[0]?.value,
      }));
    }
  }, [method, memoizedSetValueObj, modulesList, drawerOpen]);

  return (
    <div className="d-flex align-items-center hs-bg-off-white rounded">
      <Select
        options={modulesList}
        allowClear={false}
        disabled={source === "custom-action" ? disableModuleSelection : false}
        className={`rounded hs-bg-off-white w-100 h-45 hs-max-w-200-px`}
        onChange={(e) => {
          memoizedSetValueObj((prev) => ({
            ...prev,
            event: e,
            fieldApiName: null,
            fieldName: null,
            templateId: null,
            message: null,
          }));
          setFileList([]);
        }}
        placeholder="Select Module"
        value={valueObj?.event}
        suffixIcon={
          moduleLoading ? <div className="actionIconsSprite dropDownLoading" /> : <div className="actionIconsSprite fromNumberDropDownIcon" />
        }
        variant="borderless"
        notFoundContent={
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No modules!</span>}
          ></Empty>
        }
      />{" "}
      <Divider type="vertical" className="webhook-divider-vertical" />
      <Select
        mode="multiple"
        className={`rounded hs-bg-off-white h-100 w-100 ${md ? "hs-max-w-400-px" : "hs-max-w-200-px"}`}
        placeholder={`Select fields`}
        suffixIcon={
          moduleFieldsLoading ? (
            <div className="actionIconsSprite dropDownLoading" />
          ) : (
            <div className="actionIconsSprite fromNumberDropDownIcon" />
          )
        }
        filterOption={false}
        options={
          (fieldOptions &&
            fieldOptions?.map((obj) => ({
              label: (
                <span>
                  {valueObj?.event === obj?.moduleName ? (
                    <RenderOptionLabel label={obj?.fieldName} />
                  ) : (
                    <div className="marquee-container active">
                      <span className="text-capitalize d-flex marquee-option">
                        {obj?.displayModuleName?.length > 8 ? obj?.displayModuleName?.slice(0, 9) : obj?.displayModuleName}
                        <span className="actionIconsSprite right-arrow-icon" /> {obj?.fieldName}
                      </span>
                    </div>
                  )}
                </span>
              ),
              value: obj?.fieldValue,
            }))) ||
          []
        }
        onDeselect={(e) => {
          let lookupModuleName;
          let lookupModuleFieldName = e;

          let idToRemove = valueObj?.event;
          let valueToRemove = e;
          if (e.indexOf("&&") !== -1) {
            lookupModuleName = e.split("&&")[0];
            lookupModuleFieldName = e.split("&&")[1];
            idToRemove += "_::_" + lookupModuleName;
            valueToRemove = lookupModuleFieldName;
          }

          memoizedSetValueObj((prev) => {
            const newFieldApi = (valueObj?.fieldApiName || []).filter((item) => item[idToRemove] !== valueToRemove);
            return {
              ...prev,
              fieldApiName: newFieldApi,
            };
          });
        }}
        onSelect={(e) => {
          let item = fieldOptions?.find((obj) => obj.fieldValue === e);
          memoizedSetValueObj((prev) => {
            const result = {
              ...prev,
              fieldApiName: [
                ...(valueObj?.fieldApiName || []),
                {
                  [valueObj?.event === item?.moduleName ? item?.moduleName : valueObj?.event + "_::_" + item?.moduleName]: item?.fieldApiName,
                },
              ],
              fieldName: [...(valueObj?.fieldName || []), item?.fieldName],
            };
            return result;
          });
        }}
        value={valueObj?.fieldApiName?.map((obj) => {
          const key = Object.keys(obj)[0];
          const value = obj[key];
          if (key.includes("_::_")) {
            const parts = key.split("_::_");
            return `${parts[1]}&&${value}`;
          }
          return value;
        })}
        variant="borderless"
        style={{ maxHeight: 120, minHeight: 36 }}
        notFoundContent={
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No fields!</span>}
          ></Empty>
        }
      />
    </div>
  );
};
export default AddOrEditAutomationComponent;
