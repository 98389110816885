import { Col, Layout } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/omessage.css";
import { HOMEPAGE_URL, navigateToPathWithCurrentParams } from "../../pages/utils/commonUtils";
import { urlParams } from "./../../constants/AppConstants";
import SideBarMenuItem from "./SideBarItemComponents/SideBarMenuItem";

const { Sider } = Layout;

function Sidebar(props) {
  const { toggleSidebar } = props;
  let sideBarNeeded = urlParams.get("sideBar");
  const navigate = useNavigate();
  let path = window.location.pathname;
  if (path === "/zoho-crm/webhook") {
    sideBarNeeded = "false";
  }
  var sideBarFalse = "block";
  if (path === "/zoho-crm/landing" || path === "/teams/landing") {
    sideBarFalse = "d-none";
  }
  const [isSideBarNeeded] = useState(sideBarNeeded === undefined || sideBarNeeded !== "false");

  return (
    <>
      {isSideBarNeeded && (
        <Sider
          theme="light"
          className={`menuOmessage omessageSider overflow-y-auto ${sideBarFalse}`}
          style={{
            height: "100dvh",
            top: 0,
            bottom: 0,
            zIndex: 1,
            background: "white",
            borderInlineEnd: "1px solid rgb(230, 231, 236)",
          }}
        >
          <div className="demo-logo-vertical" />
          <div
            key="logo"
            style={{ height: "100px" }}
            title=""
            className="hellosendHover"
            onClick={() => navigateToPathWithCurrentParams(HOMEPAGE_URL, navigate)}
          >
            <div className="d-flex justify-content-left ps-4">
              <Col className="mt-1">
                <div className="staticIconsSprite helloSend" title=""></div>
              </Col>
            </div>
          </div>
          <SideBarMenuItem toggleSidebar={toggleSidebar} sideBarCommonHandling={false} />
        </Sider>
      )}
    </>
  );
}

export default Sidebar;
