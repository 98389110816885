import { Col, Flex, Form, Row, Spin, Tour, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import GetVariableValues from "../../../components/custom/common/GetVariableValues";
import Link from "../../../components/custom/info/Link";
import Button from "../../../components/custom/input/button/Button";
import Input from "../../../components/custom/input/input/Input";
import Select from "../../../components/custom/input/select/Select";
import { WHATSAPP_DISPLAY_NAME } from "../../../constants/AppConstants";
import { AppContext } from "../../../context/AppContext";
import useTemplates from "../../../hooks/useTemplates";
import { successNotification } from "../../utils/CommonNotifications";
import {
  capitalizeFirstLetter,
  getRandomNumber,
  getRegexForFindingPlaceHolderWithDigitsOnly,
  PATTERN_FOR_MATCHING_CURLY_BRACED_VARIABLES,
} from "../../utils/commonUtils";
import { NoChannelPresent } from "../../utils/CommonVessels";

const NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG = `There are no templates for the selected account. Kindly go to the ${WHATSAPP_DISPLAY_NAME} account and create a template`;

const AddWhatsappTemplateDrawer = React.memo(({ selectedTemplate, setSelectedTemplate, addWhatsappTemplateForm, friendlyName, add }) => {
  const [appProperties] = useContext(AppContext);

  const isFromAdd = useRef(add);

  const [whatsappObject] = useState(
    appProperties?.installedApps?.filter((installedApp) => installedApp?.right?.service_name === "whatsapp")?.[0]
  );
  const [templateOptions, setTemplateOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [module, setModule] = useState(selectedTemplate?.moduleName);
  const [currentVariableValues, setCurrentVariableValues] = useState();
  const [templateLoading, setTemplateLoading] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(selectedTemplate?.remoteTemplateId);
  const [openValueGetterModal, setOpenValueGetterModal] = useState(false);

  const { fetchTemplatesByChannel, fetchTemplateById, fetchModules, fetchSavedTemplates } = useTemplates();

  useEffect(() => {
    if (module) {
      setSelectedTemplate((prev) => ({ ...prev, moduleName: module }));
    }
  }, [module]);

  const mapTemplateLableAndValue = (templateData) => {
    if (templateData) {
      templateData = templateData.map((template) => {
        return {
          value: template?.remoteTemplateId,
          label: template?.remoteTemplateName,
          data: template,
        };
      });

      if (templateData?.length <= 0) {
        throw new Error(NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG);
      }
      return templateData;
    } else {
      throw new Error(NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG);
    }
  };

  const callFetchWhatsappTemplates = useCallback(async () => {
    setTemplateLoading(true);
    fetchTemplatesByChannel(whatsappObject?.integProps?.integId, appProperties, "whatsapp")
      .then((res) => {
        if (res?.success) {
          setTemplateOptions(mapTemplateLableAndValue(res?.data));
        } else {
          throw new Error(NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG);
        }
      })
      .catch((e) => {
        console.warn(e.message);
      })
      .finally(() => setTemplateLoading(false));
  }, [appProperties, fetchTemplatesByChannel, whatsappObject?.integProps?.integId]);

  const handleModuleSelect = (selectedModule) => {
    setModule(selectedModule);
  };

  const callFetchModules = useCallback(async () => {
    setModuleLoading(true);
    const response = await fetchModules(appProperties, false, "templates");
    const parsedResponse = response?.data?.map((moduleName) => {
      return {
        label: capitalizeFirstLetter(moduleName),
        value: moduleName,
        data: moduleName,
      };
    });
    setModuleOptions(parsedResponse);
    setModuleLoading(false);
  }, [appProperties, fetchModules]);

  useEffect(() => {
    if (whatsappObject?.right?.auth?.authorized) {
      callFetchWhatsappTemplates();
      callFetchModules();
    }

    if (friendlyName) {
      addWhatsappTemplateForm.setFieldValue("friendlyName", friendlyName);
    }

    if (module) {
      addWhatsappTemplateForm.setFieldValue("selectedModule", module);
    }
  }, [appProperties, callFetchModules, callFetchWhatsappTemplates, fetchTemplatesByChannel, whatsappObject]);

  const handleTemplateSelect = (selectedTemplateId) => {
    isFromAdd.current = true;
    setSelectedTemplateId(selectedTemplateId);
  };

  useEffect(() => {
    if (selectedTemplateId && templateOptions) {
      let templateObject;
      if (isFromAdd.current) {
        templateObject = templateOptions?.find((item) => item?.value === selectedTemplateId)?.data;
      } else {
        templateObject = selectedTemplate;
      }

      if (!templateObject) return;
      let { integId, remoteTemplateId, remoteTemplateName, whatsappBusinessAccountId } = templateObject;

      const parseTemplate = (template) => {
        template.components = template.components.map((component, componentIndex) => {
          const splitPartsFromValue = component.value.split(PATTERN_FOR_MATCHING_CURLY_BRACED_VARIABLES).filter((val) => val);
          let totalNumberOfVariablesPresent = 0;
          let renderObjects = [];
          splitPartsFromValue.forEach((slittedPart, partIndex) => {
            let isVariable = false;
            let renderText = slittedPart;
            let id = `${component.type}:${componentIndex}`;
            if (slittedPart.match(PATTERN_FOR_MATCHING_CURLY_BRACED_VARIABLES)) {
              isVariable = true;
              const indexOfActualValueToBeInserted = Number(slittedPart.substring(2, slittedPart.length - 2) - 1);
              id = id + `:${indexOfActualValueToBeInserted}`;
              totalNumberOfVariablesPresent++;

              if (component.values) {
                renderText = `{{${component.values[indexOfActualValueToBeInserted]?.field}}}`;
              }
            } else {
              id = id + `:${partIndex}:${getRandomNumber(3)}`;
            }

            slittedPart = slittedPart.trim();
            renderObjects.push({
              text: renderText,
              isVariable: isVariable,
              id: id,
            });
          });
          component.totalNumberOfVariablesPresent = totalNumberOfVariablesPresent;
          component.totalNumberOfVariablesNotFilled = totalNumberOfVariablesPresent;
          component.renderObjects = renderObjects;

          return component;
        });
        return template;
      };

      if (isFromAdd.current) {
        setTemplateLoading(true);
        fetchTemplateById(integId, remoteTemplateId, remoteTemplateName, "whatsapp", appProperties, whatsappBusinessAccountId)
          .then((response) => {
            if (response) {
              setSelectedTemplate(parseTemplate(response));
            }
          })
          .finally(() => setTemplateLoading(false));
      } else if (selectedTemplate) {
        setSelectedTemplate(parseTemplate(selectedTemplate));
      }
    }
  }, [selectedTemplateId, templateOptions]);

  if (!whatsappObject?.right?.auth?.authorized) {
    return (
      <NoChannelPresent
        message={`${WHATSAPP_DISPLAY_NAME} channel is not connected`}
        buttonText={"Add channel"}
        baseUrl={"/channels/whatsapp"}
        newTab={false}
      />
    );
  }

  if (templateLoading) {
    return (
      <Flex vertical={true} justify="center" className="h-100">
        <Spin spinning={true} />
      </Flex>
    );
  }

  if (templateOptions?.length <= 0) {
    return <NoWhatsappTemplateMessage />;
  }

  return (
    <div className="d-grid gap-4">
      <Form form={addWhatsappTemplateForm} className="d-grid gap-3">
        <Row className="mt-3" justify={"center"}>
          <Col span={12}>
            <div className="px-1">
              <Select
                customProps={{
                  placeholder: "Select Template",
                  popupMatchSelectWidth: 410,
                  onSelect: handleTemplateSelect,
                  value: selectedTemplateId,
                }}
                loading={templateLoading}
                selectSource={"Templates"}
                name={"selectedTemplate"}
                options={templateOptions}
                label={"Template"}
                defaultValue={selectedTemplateId}
                searchPlaceHolder={"Search Template"}
                required={true}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="px-1">
              <Input
                formItemName={"friendlyName"}
                formItemLabel={"Friendly name"}
                placeHolder={"Enter a friendly name"}
                autoFocus={false}
                required={true}
                defaultValue={friendlyName}
                value={friendlyName}
              />
            </div>
          </Col>
        </Row>
        <Select
          customProps={{
            placeholder: "Select Module",
            popupMatchSelectWidth: 410,
            bordered: null,
            onSelect: async (e) => {
              handleModuleSelect(e);
            },
          }}
          loading={moduleLoading}
          defaultValue={module}
          selectSource={"Modules"}
          options={moduleOptions}
          label={"Module"}
          name={"selectedModule"}
          searchPlaceHolder={"Search Module"}
          required={true}
        />
        <WhatsappPreviewArea
          selectedTemplate={selectedTemplate}
          module={module}
          openValueGetterModal={openValueGetterModal}
          setOpenValueGetterModal={setOpenValueGetterModal}
          appProperties={appProperties}
          setCurrentVariableValues={setCurrentVariableValues}
          currentVariableValues={currentVariableValues}
          setSelectedTemplate={setSelectedTemplate}
          moduleLoading={moduleLoading}
          setModuleLoading={setModuleLoading}
          templateLoading={templateLoading}
        />
      </Form>
    </div>
  );
});

function NoWhatsappTemplateMessage() {
  return (
    <Flex vertical={true} className="h-75" align="center" justify="center">
      <Flex vertical={true} justify="center" align="center" className="h-75 hs-w-90 hs-bg-off-white hs-border-dark-grey hs-border-15">
        <Flex gap={"middle"} vertical align="center" justify="center" className="h-75 w-75">
          <Flex justify="center" align="center" className="h-25">
            <div className="staticIconsSprite add-blue-icon"></div>
          </Flex>
          <Flex vertical={true} align="center" justify="center" className="hs-h-45">
            <span className="hs-fw-600">No {WHATSAPP_DISPLAY_NAME} Templates Found</span>
            <span className="text-center hs-placeholder-text">
              It looks like there are no approved {WHATSAPP_DISPLAY_NAME} templates in your linked {WHATSAPP_DISPLAY_NAME} account. Create your
              first template and then import it into HelloSend.
            </span>
          </Flex>
          <Flex justify="center" align="center" className="hs-h-20">
            <Button onClick={() => window.open("https://business.facebook.com/latest/whatsapp_manager/overview", "_blank")}>
              Create Template Now {">"}
            </Button>
          </Flex>
          <Flex justify="center" align="center" className="hs-h-20">
            <Link
              externalLink
              textClasses={"hs-fs-13 text-decoration-underline hs-text-white-space-no-wrap"}
              baseUrl={"https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/"}
            >
              Help Guide: Create {WHATSAPP_DISPLAY_NAME} Template on Facebook
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function WhatsappPreviewArea({
  module,
  selectedTemplate,
  openValueGetterModal,
  setOpenValueGetterModal,
  appProperties,
  setCurrentVariableValues,
  currentVariableValues,
  setSelectedTemplate,
  moduleLoading,
  setModuleLoading,
  templateLoading,
}) {
  const [fields, setFields] = useState();
  const { fetchFields } = useTemplates();
  const [fieldsLoading, setFieldsLoading] = useState(moduleLoading);

  useEffect(() => {
    if (module) {
      const fetchDataResponse = async () => {
        return await fetchFields(appProperties, module);
      };
      setFieldsLoading(true);
      fetchDataResponse()
        .then((response) => {
          if (response?.success) {
            let responseData = response?.fields;
            if (responseData) {
              responseData = responseData.map((item) => {
                return {
                  label: item?.fieldName,
                  value: item?.fieldApiName,
                  ...item,
                };
              });
            } else {
              responseData = [];
            }

            setFields(responseData);
          }
        })
        .finally(() => {
          setFieldsLoading(false);
        });
    }
  }, [appProperties, fetchFields, module]);

  return (
    <div className="h-100 w-100">
      <div className="hs-h-whatsapp-preview-area rounded hs-input-box whatsapp-doodle-bg overflow-scroll">
        {module && selectedTemplate ? (
          <WhatsappComponentsContainer
            selectedTemplate={selectedTemplate}
            openValueGetterModal={openValueGetterModal}
            setOpenValueGetterModal={setOpenValueGetterModal}
            appProperties={appProperties}
            module={module}
            setCurrentVariableValues={setCurrentVariableValues}
            currentVariableValues={currentVariableValues}
            moduleLoading={moduleLoading}
            setModuleLoading={setModuleLoading}
            templateLoading={templateLoading}
            fields={fields}
            fieldsLoading={fieldsLoading}
            setSelectedTemplate={setSelectedTemplate}
          />
        ) : (
          <div className="d-grid h-100 align-items-center justify-content-center hs-placeholder-text">
            {selectedTemplate ? <>Select module to configure</> : <>Select template to configure</>}
          </div>
        )}
      </div>
    </div>
  );
}

function WhatsappComponentsContainer({
  selectedTemplate,
  openValueGetterModal,
  setOpenValueGetterModal,
  setCurrentVariableValues,
  currentVariableValues,
  setSelectedTemplate,
  fields,
  fieldsLoading,
}) {
  const [getVariableValuesForm] = Form.useForm();
  const elementsRef = useRef({});
  const allDisplayTextConjoinedString = selectedTemplate?.components?.map((component) => component?.value).join(" ");
  const totalNumberOfVariablesPresentLength = useMemo(
    () => allDisplayTextConjoinedString?.match(getRegexForFindingPlaceHolderWithDigitsOnly())?.length ?? 0,
    [allDisplayTextConjoinedString]
  );

  const totalNumberOfVariablesPresent = useRef(totalNumberOfVariablesPresentLength);

  const handleOnClose = () => {
    setOpenValueGetterModal(false);
  };

  const handleOnOpen = (value, name, index, refToOpen) => {
    setCurrentVariableValues({ value: value, name: name, componentIndex: index, refToOpen: refToOpen });
    setOpenValueGetterModal(true);
  };

  const doBeforeClose = () => {
    handleOnClose();
    setCurrentVariableValues();
    getVariableValuesForm.resetFields();
  };

  const findRenderObjectIndexById = (renderObjects, id) => {
    return renderObjects.findIndex((renderObject) => renderObject.id === id);
  };

  const handleOnModalSave = async () => {
    try {
      const getVariableValuesFormValues = await getVariableValuesForm.validateFields();

      const index = parseInt(currentVariableValues?.value ?? 1);
      setSelectedTemplate((prev) => {
        const componentIndex = prev?.components?.findIndex((component) => currentVariableValues.name === component.type);
        if (componentIndex !== -1) {
          prev.components[componentIndex].values = prev?.components?.[componentIndex]?.values ?? [];
          prev.components[componentIndex].values[index] = prev?.components?.[componentIndex]?.values?.[index] ?? {};
          prev.components[componentIndex].values[index].field = getVariableValuesFormValues?.selectedField;
          prev.components[componentIndex].values[index].defaultValue = getVariableValuesFormValues?.defaultValue;
          const renderObjectIndex = findRenderObjectIndexById(prev.components[componentIndex]?.renderObjects, currentVariableValues.refToOpen);

          prev.components[componentIndex].totalNumberOfVariablesNotFilled =
            prev.components[componentIndex].totalNumberOfVariablesPresent - prev.components[componentIndex].values.length;

          prev.components[componentIndex].renderObjects[renderObjectIndex].text = "{{" + getVariableValuesFormValues?.selectedField + "}}";
          console.log("debug_alex handleOnModalSave modal component", prev.components[componentIndex]);
          successNotification("Saved successfully!");
        }
        return prev;
      });
      doBeforeClose();
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <Tour
        open={openValueGetterModal}
        rootClassName="w-auto"
        onClose={handleOnClose}
        closeIcon={<></>}
        steps={[
          {
            title: (
              <GetVariableValues
                okButtonProps={{
                  htmlType: "submit",
                  buttonText: "Save",
                  onClick: handleOnModalSave,
                }}
                cancelButtonProps={{
                  buttonText: "Cancel",
                  onClick: doBeforeClose,
                }}
                selectSource={"fields"}
                fields={fields}
                getVariableValuesForm={getVariableValuesForm}
              />
            ),
            target: () => elementsRef.current[`var-ref-${currentVariableValues?.refToOpen}`],
          },
        ]}
        indicatorsRender={() => null}
      />

      <div className="d-grid gap-2 p-1 px-3">
        {selectedTemplate?.components?.map((component, index) => {
          return (
            <WhatsappComponent
              totalNumberOfVariablesPresent={totalNumberOfVariablesPresent}
              fieldsLoading={fieldsLoading}
              handleOnOpen={handleOnOpen}
              fields={fields}
              selectedTemplate={selectedTemplate}
              handleOnClose={doBeforeClose}
              currentVariableValues={currentVariableValues}
              name={component?.type?.toLowerCase()}
              key={getRandomNumber(5)}
              elementsRef={elementsRef}
              componentIndex={index}
              component={component}
            >
              {component?.value}
            </WhatsappComponent>
          );
        })}
      </div>
    </>
  );
}

function WhatsappComponent({ name, children, handleOnOpen, elementsRef, selectedTemplate, totalNumberOfVariablesPresent, component }) {
  const handleVariableClick = (id) => {
    const idSegments = id.split(":"); // 0:variableType, 1:componentIndex, 2:varibaleIndex
    handleOnOpen(Number(idSegments[2]), idSegments[0], Number(idSegments[1]), id);
  };

  const handleOnClick = useCallback(
    (id) => {
      if (handleVariableClick) {
        handleVariableClick(id);
      }
    },
    [handleVariableClick]
  );

  const renderContent = useCallback(() => {
    if (component?.renderObjects) {
      return component.renderObjects.map((renderObject) => {
        if (renderObject?.isVariable) {
          return (
            <button
              ref={(el) => (elementsRef.current[`var-ref-${renderObject.id}`] = el)}
              key={`var_${renderObject.id}`}
              style={{ all: "unset" }}
              className="hs-color-violet cursor-pointer"
              onClick={() => handleOnClick(renderObject.id)}
            >
              {renderObject.text}
            </button>
          );
        } else {
          const parts = renderObject?.text.split("\n");
          return parts.map((part, partIndex) => (
            <React.Fragment key={`text_${renderObject?.id}_${partIndex}`}>
              <span className={`text-wrap me-0 ${component?.type !== "header" ? "hs-word-break-keep-all" : ""}`} style={{ display: "inline" }}>
                {part}
              </span>
              {partIndex < parts.length - 1 && <br />}
            </React.Fragment>
          ));
        }
      });
    }
    return null;
  }, [component?.renderObjects, handleOnClick]);

  const validateInputFields = (e) => {
    const filledValuesLength = selectedTemplate?.components?.reduce((accumulator, component) => {
      return accumulator + (component?.values?.length ?? 0);
    }, 0);

    if (filledValuesLength !== totalNumberOfVariablesPresent.current) {
      const currentComponent = selectedTemplate?.components?.find((component) => component.type === e.field);
      if (currentComponent.totalNumberOfVariablesNotFilled > 0) {
        return Promise.reject(new Error("Please update all values"));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Input
      validator={validateInputFields}
      name={name}
      customClasses={`bg-transparent hs-input-prefix ${name === "body" || name === "header" ? "py-3" : ""}`}
      formItemLabel={capitalizeFirstLetter(name)}
      formItemName={name}
      readOnly
      size={name === "body" || name === "header" ? "" : "m"}
      aria-readonly
      autoFocus={false}
      value={children}
      supportVariables={true}
      prefix={renderContent()}
      required={true}
    />
  );
}

export default AddWhatsappTemplateDrawer;
